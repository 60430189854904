<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-money-transfer-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">درامد از گروه</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div class="grid sm:grid-cols-3 gap-2">
        <div
          class="bg-blueGray-100 border border-blueGray-200 rounded-md p-3 text-center"
        >
          <div class="text-lg py-2">کل درامد</div>
          <div class="text-lg py-2 font-bold">
            {{ $number.format(group.info.account_income + group.info.ad_income) }}
          </div>
          <Button
            :to="{
              name: 'GroupOwnerSettingIncomeTo',
              params: { id: $route.params.id, groupId: group.id },
            }"
            class="text-sm h-11 lg:text-lg"
            color="teal"
          >
            انتقال به کیف پول
          </Button>
        </div>
        <div
          class="bg-blueGray-100 border border-blueGray-200 rounded-md p-3 text-center"
        >
          <div class="text-lg py-2">درامد از اشتراک</div>
          <div class="text-lg py-2 font-bold">
            {{ $number.format(group.info.account_income) }}
          </div>
          <div class="flex gap-2">
            <Button
              :to="{
                name: 'GroupOwnerSettingIncomeAccount',
                params: { id: $route.params.id, groupId: $route.params.groupId },
              }"
              class="text-sm h-11 lg:text-lg"
              color="teal"
            >
              خریدها
            </Button>
            <Button
              :to="{
                name: 'GroupOwnerSettingBranchs',
                params: { id: $route.params.id, groupId: $route.params.groupId },
                hash: '#accounts',
              }"
              class="text-sm h-11 lg:text-lg"
              color="cyan"
            >
              تنظیمات
            </Button>
          </div>
        </div>
        <div
          class="bg-blueGray-100 border border-blueGray-200 rounded-md p-3 text-center"
        >
          <div class="text-lg py-2">درامد از تبلیغ هدفمند</div>
          <div class="text-lg py-2 font-bold">
            {{ $number.format(group.info.ad_income) }}
          </div>
          <div class="flex gap-2">
            <Button
              :to="{
                name: 'GroupOwnerSettingIncomeAds',
                params: { id: $route.params.id, groupId: $route.params.groupId },
              }"
              class="text-sm h-11 lg:text-lg"
              color="teal"
            >
              سفارش ها
            </Button>
            <Button
              :to="{
                name: 'GroupOwnerSettingMain',
                params: { id: $route.params.id, groupId: $route.params.groupId },
                hash: '#info',
              }"
              class="text-sm h-11 lg:text-lg"
              color="cyan"
            >
              تنظیمات
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      group: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group-income/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
